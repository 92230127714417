import { getUserData } from '@/auth/utils'

const defaultNaviguation = [
  {
    title: 'Nos programmes',
    route: 'programmes',
    icon: 'LayoutIcon',
  },
]

const adminNavigation = [
  {
    header: 'Administration',
  },
  {
    title: 'Utilisateurs',
    route: 'admin-utilisateurs',
    icon: 'UsersIcon',
  },
  {
    title: 'Gestion Programmes',
    route: 'admin-programmes',
    icon: 'LayoutIcon',
  },
]

const dynamicNaviguation = []

const user = getUserData()
if (user && user.role && user.role.includes('ROLE_ADMIN')) {
  adminNavigation.forEach(e => {
    dynamicNaviguation.push(e)
  })
}

const naviguation = defaultNaviguation.concat(dynamicNaviguation)

export default naviguation
